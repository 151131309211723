.header {
  color: var(--white);
  font-family: var(--my-font);
  width: 100%;
  height: fit-content;
  padding: 1.2rem 0.1em;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerLogo {
  font-size: 1.8rem;
  font-weight: 600;
}

.headerNav {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: auto 2rem;
  color: #919191;
}

.navLink {
  color: inherit;
  margin: auto 1rem;
  font-size: 1rem;
  font-weight: 400;
  cursor: default;
  text-decoration: inherit;
  cursor: pointer;
  padding: 0.4rem 0.6rem;
  border-radius: 8px;
  background-color: inherit;
  transition: background-color, color 0.2s ease-in-out;
}

.navLink:hover {
  background-color: var(--primary-l);
  color: var(--black);
}

.active {
  color: var(--primary-l);
}

.friesContainer {
  height: 54px;
  width: 54px;
  padding: 18px 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.crossContainer {
  height: 54px;
  width: 54px;
  padding: 18px 12px;
  position: relative;
  margin-left: 0.4em;
}

.crossOne {
  width: 50%;
  height: 4%;
  background-color: var(--black);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  transform: rotate(45deg);
}
.crossTwo {
  width: 50%;
  height: 4%;
  background-color: var(--black);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  transform: rotate(-45deg);
}
.fries {
  width: 100%;
  background-color: var(--white);
  height: 8%;
}
.fry {
  width: 70%;
  align-self: flex-end;
  background-color: var(--white);
  height: 8%;
}

.headerNav {
  position: fixed;
  right: -120%;
  top: 0;
  width: fit-content;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--primary-l);
  margin: 0 0 0;
  z-index: 1;
  align-items: flex-start;
  color: var(--black);
  transition: all 0.4s ease-in-out;
  box-shadow: #000000 0px 0px 12px;
}

.active {
  background-color: var(--primary-d);
}

.navLink {
  font-size: 1em;
  margin: 1em;
  text-align: left;
  padding: 0.5em;
}

.headerNavOpen {
  right: 0;
}
