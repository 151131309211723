.products_container_d {
  display: grid;
  grid-template-columns: auto auto auto auto;
  background-color: var(--primary-l);
  width: 100%;
  height: fit-content;
  margin: 1rem;
  padding: 1rem;
  border-radius: 1rem;
  /* box-shadow: inset 0 0 16px var(--black); */
}

.carousel_d {
  width: 80%;
  height: fit-content;
  margin: 1rem 2rem;
  padding: 1.6rem 1rem;
}

.carousel_img_d {
  height: 36vh;
  width: auto;
  max-width: 90%;
}

.carousel_desc_d {
  font-size: 1.6rem;
}

.products_item_d {
  background-color: var(--white);
  border-radius: 0.8rem;

  min-height: fit-content;
  padding: 0.4rem;
  width: 90%;
  margin: 1rem auto;
  color: var(--black);
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: #000000 0 0 16px;
}

.products_item_title_d {
  color: var(--black);
  font-size: 1.6rem;
  font-weight: 600;
}

.products_item_content_d {
  color: var(--primary-d);
  font-size: 1.2rem;
  font-weight: 600;
}

.tableContainer_d {
  display: flex;
  flex-direction: row;
  background-color: var(--primary-l);
  margin: 2rem auto 2rem auto;
  padding: 0.8rem 0.4rem;
  border-radius: 1rem;
}

.tableColumn_d {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  margin: 0 0.4rem;
  border-radius: 1rem;
}

.tableColumnTitle_d {
  color: var(--primary-d);
  font-size: 2rem;
  font-weight: 600;
  padding: 1rem;
}

.tableContent_d {
  color: var(--black);
  font-size: 1.6rem;
  font-weight: 600;
  margin: 0.2rem;
}

.tableUnit_d {
  color: var(--primary-d);
  font-size: 1.2rem;
  font-weight: 500;
}

.separator_d {
  width: 100%;
  height: 2px;
  background: -webkit-linear-gradient(
    right,
    #ffffff00,
    var(--black),
    var(--black),
    #ffffff00
  );
  margin-bottom: 0.4rem;
}
