.rec.rec-arrow {
  background-color: aliceblue;
}

.rec.rec-arrow:hover {
  background-color: var(--primary-l);
}

.rec.rec-dot {
  background-color: aliceblue;
  border-color: var(--primary-d);
}

.rec.rec-dot_active {
  background-color: var(--primary-l);
}
