.import {
  animation: importAnim 6s infinite;
}
@keyframes importAnim {
  0% {
    color: #e67e22;
  }
  31% {
    color: #e67e22;
  }
  34% {
    color: white;
  }
  64% {
    color: white;
  }
  67% {
    color: #27ae60;
  }
  97% {
    color: #27ae60;
  }
  100% {
    color: #e67e22;
  }
}
.export {
  animation: importAnim 6s infinite;
  animation-delay: -4s;
}
.and {
  animation: importAnim 6s infinite;
  animation-delay: -2s;
}
