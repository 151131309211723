.products_container_m {
  display: flex;
  flex-direction: column;
  background-color: var(--primary-l);
  width: 90%;
  max-height: 60vh;
  overflow-y: scroll;
  height: fit-content;
  margin: 1rem;
  padding: 1rem;
  border-radius: 1rem;
  /* box-shadow: inset 0 0 16px var(--black); */
}

.carousel_m {
  /* width: 90%; */
  height: 50vh;
  margin: 1rem 2rem;
  padding: 1.6rem 1rem;
}

.carousel_img_m {
  height: 24vh;
  max-width: 95%;
}

.carousel_desc_m {
  font-size: 1.2em;
}

.products_item_m {
  background-color: var(--white);
  border-radius: 0.8rem;

  min-height: fit-content;
  padding: 0.4rem;
  width: 90%;
  margin: 1rem auto;
  color: var(--black);
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: #000000 0 0 16px;
}

.products_item_title_m {
  color: var(--black);
  font-size: 1.6rem;
  font-weight: 600;
}

.products_item_content_m {
  color: var(--primary-d);
  font-size: 1.2rem;
  font-weight: 600;
}

.tableContainer_m {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-x: scroll;
  background-color: var(--primary-l);
  margin: 2rem auto 2rem auto;
  padding: 0.4em 0.2em;
  border-radius: 0.5em;
}

.tableColumn_m {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  margin: 0 0.2em;
  border-radius: 0.5em;
}

.tableColumnTitle_m {
  color: var(--primary-d);
  font-size: 1em;
  font-weight: 600;
  padding: 0.4em;
}

.tableContent_m {
  color: var(--black);
  font-size: 0.8em;
  font-weight: 600;
  margin: 0.2rem;
}

.tableUnit_m {
  color: var(--primary-d);
  font-size: 0.6em;
  font-weight: 500;
}

.separator_m {
  width: 100%;
  height: 1px;
  background: -webkit-linear-gradient(
    right,
    #ffffff00,
    var(--black),
    var(--black),
    #ffffff00
  );
  margin: 0.2em auto;
}
