.teamContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: top;
  height: fit-content;
  margin-top: 2rem;
  font-size: 1.6rem;
}

.teamSeparator {
  width: 2px;
  background-image: -webkit-linear-gradient(
    top,
    #ffffff00,
    #ffffff,
    #ffffff,
    #ffffff00
  );
  margin: 0 2rem;
}

.teamMemberContainer {
  width: 45%;
  background-color: var(--primary-l);
  border-radius: 1.6rem 0;
  padding: 0.8rem;
  height: fit-content;
  border: 4px solid var(--primary-d);
  transition: box-shadow 0.4s;
}

.teamMemberContainer:hover {
  box-shadow: var(--ui-grey) 0px 0px 16px;
}

.teamMemberName {
  color: var(--black);
  font-size: 2.4rem;
  font-weight: 800;
}

.teamMemberTitle {
  color: var(--black);
  font-size: 1.6rem;
  font-weight: 800;
}

.teamMemberContent {
  color: var(--black);
  text-align: left;
}
