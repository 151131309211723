.formContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form {
  display: flex;
  flex-direction: column;
  background-color: var(--primary-d);
  padding: 0.8em;
  width: 90%;
  border-radius: 0.6em;
}

.form label {
  text-align: left;
  font-size: 0.8em;
  margin-top: 0.4em;
}

.form input {
  width: 100%;
  height: 2.4em;
  border-radius: 0.4em;
  border: 1px solid var(--primary-d);
  padding: 0.4em;
  color: #000;
  margin-top: 0.4em;
  font-size: 0.8em;
  font-weight: 600;
}

.form textarea {
  width: 100%;
  height: 4em;
  border-radius: 0.4em;
  border: 1px solid var(--primary-d);
  padding: 0.4em;
  color: #000;
  margin-top: 0.4em;
  font: inherit;
  font-weight: 600;
}

.form button {
  width: fit-content;
  font-size: 1.2em;
  background-color: var(--ui-success);
  border: none;
  padding: 0.4em;
  border-radius: 0.4em;
  margin: 0.8em auto 0;
}

.formTitle {
  color: var(--white);
  font-size: 1.6rem;
  font-weight: 600;
}

.formProcessing {
  background-color: var(--ui-neutral);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem;
  width: 45%;
  color: var(--white);
  font-size: 2rem;
  border-radius: 1.2rem;
}
.formSuccess {
  background-color: var(--ui-success);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem;
  width: 45%;
  color: var(--black);
  font-size: 2rem;
  border-radius: 1.2rem;
}

.formMessage {
  margin-bottom: 4rem;
  color: inherit;
}

.ldsdualring {
  display: inline-block;
  width: 80px;
  height: 80px;
  transform: translate(-10px);
}
.ldsdualring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.thumbsup {
  font-size: 10rem;
}

.errorMsg {
  width: 100%;
  text-align: left;
  margin-top: 0.8rem;
  background-color: rgb(255, 233, 233);
  border: 1px solid red;
  border-radius: 0.8rem;
  padding: 0.4rem;
  color: red;
}
