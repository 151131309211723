* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #fff;
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;900&display=swap");

:root {
  --black: #000000;
  --primary-l: #9d8fcc;
  --primary-d: #54428e;
  --white: #ffffff;
  --ui-neutral: #3498db;
  --ui-success: #2ecc71;
  --ui-warn: #e74c3c;
  --ui-grey: #a1a1a1;
  --my-font: "Inter", sans-serif;
}

.body {
  height: fit-content;
  background-color: var(--black);
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

.page-wrapper {
  width: 100%;
  padding-left: 20vw;
  padding-right: 20vw;
  height: fit-content;
  min-height: 100vh;
}

.static-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  background: radial-gradient(ellipse at bottom, #0d1d31 0%, #0c0d13 100%);
}

.sect {
  width: 100%;
  height: fit-content;
  padding-top: 12vh;
  padding-bottom: 12vh;
  display: flex;
  flex-direction: column;
  margin: 1.6rem auto 1rem;
  margin-bottom: 0;
  font-family: var(--my-font);
  align-items: center;
  text-align: center;
  justify-content: center;
}

.sect-title {
  color: var(--white);
  font-weight: 800;
  font-size: 5.4rem;
}
.sect-title-medium {
  color: var(--white);
  font-weight: 800;
  font-size: 3.2rem;
}
.sect-title-submedium {
  color: var(--white);
  font-weight: 600;
  font-size: 2.6rem;
  margin-top: 1rem;
}
.sect-content {
  color: var(--ui-grey);
  margin-top: 1rem;
  font-weight: 400;
  font-size: 2rem;
}
.sect-content-highlighted {
  color: var(--primary-l);
  font-weight: 400;
  font-size: 2rem;
}

.content-grid-container {
  margin-top: 1.6rem;
  font-size: 1.4rem;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  /* border: 1px solid red; */
  margin-bottom: -3rem;
}

.content-grid-container-column {
  width: 33%;
  margin: 0 1rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content-grid-item {
  padding: 1rem;
  border-radius: 8px;
  margin: 1rem 0;
  height: fit-content;
  text-align: left;
}

.content-grid-item-1,
.content-grid-item-3,
.content-grid-item-2 {
  background-color: var(--primary-d);
}

.content-grid-item-4,
.content-grid-item-5,
.content-grid-item-6,
.content-grid-item-7 {
  background-color: var(--white);
  color: var(--black);
}

.flag-list {
  margin: 1rem 0;
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.flag-list-item {
  height: 60px;
  width: 100px;
  border-radius: 4px;
  margin: 1rem;
}

.flagimg {
  border-radius: 10px;
  box-shadow: #ffffffaa 0px 0px 10px;
}

.flag-list-item-text {
  margin-top: 0.4rem;
  color: var(--ui-grey);
}

.stat-container {
  margin-top: 2rem;
  margin-bottom: -6rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.stat-item {
  margin: 1.6rem;
}

.stat-item-num {
  font-size: 3.6rem;
  font-weight: 900;
}

.stat-unit {
  font-size: 1.8rem;
  font-weight: 600;
  color: var(--primary-l);
}

.stat-item-desc {
  font-size: 1.8rem;
}

.stat-separator {
  width: 2px;
  background-image: -webkit-linear-gradient(
    top,
    #000000,
    #ffffff,
    #ffffff,
    #000000
  );
}
