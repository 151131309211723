.teamContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: top;
  height: fit-content;
  margin-top: 2em;
  font-size: 1.6em;
}

.teamMemberContainer {
  width: 100%;
  background-color: var(--primary-l);
  border-radius: 0.8em 0;
  padding: 0.4em;
  height: fit-content;
  margin: 0.4em 0;
  border: 4px solid var(--primary-d);
  transition: box-shadow 0.4s;
}

.teamMemberContainer:hover {
  box-shadow: var(--ui-grey) 0px 0px 16px;
}

.teamMemberName {
  color: var(--black);
  font-size: 1.2em;
  font-weight: 800;
}

.teamMemberTitle {
  color: var(--black);
  font-size: 0.8em;
  font-weight: 800;
}

.teamMemberContent {
  margin-top: 0.4em;
  color: var(--black);
  text-align: left;
}
