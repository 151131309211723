.sect-m {
  width: 100%;
  height: fit-content;
  padding-top: 12vh;
  padding-bottom: 12vh;
  display: flex;
  flex-direction: column;
  margin: 1.6rem auto 1rem;
  margin-bottom: 0;
  font-family: var(--my-font);
  align-items: center;
  text-align: center;
  justify-content: center;

  padding: 2em 0.5em;
  margin: 0.5em auto;
  height: fit-content;
}

.sect-title-m {
  color: var(--white);
  font-weight: 800;
  font-size: 2.4em;
}

.sect-title-medium-m {
  color: var(--white);
  font-weight: 800;
  font-size: 1.6em;
}
.sect-title-submedium-m {
  color: var(--white);
  font-weight: 600;
  font-size: 1.4em;
  margin-top: 1rem;
}

.sect-content-m {
  font-size: 1.2em;
}

.sect-content-highlighted-m {
  color: var(--primary-l);
  font-weight: 400;
  font-size: 1em;
}

.sect-content span-m {
  font-size: 1.2em;
}

.content-grid-container-m {
  flex-direction: column;
}

.content-grid-container-column-m {
  width: 90%;
  margin: auto;
}

.stat-container-m {
  display: flex;
  flex-direction: column;
  margin: auto;
  text-align: center;
  align-items: center;
}

.stat-item-m {
  width: 80%;
  height: fit-content;
}

.stat-item-num-m {
  height: fit-content;
  font-size: 3em;
}

.stat-unit-m {
  font-size: 0.4em;
}

.stat-item-desc-m {
  font-size: 1.2em;
}

.flag-list-m {
  display: grid;
  grid-template-columns: auto auto;
  row-gap: 1.4em;
  width: 100%;
  text-align: center;
  justify-content: center;
}

.flag-list-item-m {
  height: 60px;
  width: 100px;
  border-radius: 4px;
  margin: 1rem;
}

.pageWrapper-m {
  width: 100%;
  height: fit-content;
  padding-bottom: 0;
  min-height: 100vh;
  padding-left: 5vw;
  padding-right: 5vw;
  margin-bottom: 0;
}

.terms-li-m {
  font-size: 0.6em;
}
