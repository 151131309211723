.header {
  color: var(--white);
  font-family: var(--my-font);
  width: 100%;
  height: fit-content;
  padding: 1.2rem 0.4rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.headerLogo {
  font-size: 1.8rem;
  font-weight: 600;
}

.headerNav {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: auto 2rem;
  color: #919191;
}

.navLink {
  color: inherit;
  margin: auto 1rem;
  font-size: 1rem;
  font-weight: 400;
  cursor: default;
  text-decoration: inherit;
  cursor: pointer;
  padding: 0.4rem 0.6rem;
  border-radius: 8px;
  background-color: inherit;
  transition: background-color, color 0.2s ease-in-out;
}

.navLink:hover {
  background-color: var(--primary-l);
  color: var(--black);
}

.active {
  color: var(--primary-l);
}
