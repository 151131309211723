.footer {
  background-color: var(--primary-d);
  width: 100vw;
  margin-left: -20vw;
  height: fit-content;
  padding: 1.6rem 20vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 1.2rem;
  font-weight: 500;
  font-family: var(--my-font);
}

.footer a {
  text-decoration: inherit;
  margin: 0.1rem 0;
}

.headerLogo {
  font-size: 2.4rem;
  font-weight: 800;
  margin: 1rem 0;
}

.footerContainer {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1rem 0;
}

.footerSeparator {
  margin: 1rem 0;
  width: 100%;
  height: 2px;
  background-image: -webkit-linear-gradient(
    right,
    #00000000,
    #000000,
    #000000,
    #00000000
  );
}

.footerLineContainer {
  margin: 1rem 0;
  display: flex;
  flex-direction: row;
}

.footerLineContainer div {
  margin-right: 1rem;
}

.footerNavTitle {
  font-size: 1.6rem;
  color: var(--black);
  font-weight: 700;
}

.footerNavLinks {
  display: flex;
  flex-direction: column;
}

.tagline {
  margin: 0.4rem;
}

