.footer {
  background-color: var(--primary-d);
  width: 100vw;
  margin-left: -5vw;
  margin-bottom: 0;
  height: fit-content;
  padding: 1em 0.8em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 1.2em;
  font-weight: 500;
  font-family: var(--my-font);
}

.footer a {
  text-decoration: inherit;
  margin: 0.1rem 0;
}

.headerLogo {
  font-size: 1.2em;
  font-weight: 800;
  margin: 1rem 0;
}

.footerContainer {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin: 1rem 0;
}

.footerSeparator {
  margin: 1rem 0;
  width: 100%;
  height: 2px;
  background-image: -webkit-linear-gradient(
    right,
    #00000000,
    #000000,
    #000000,
    #00000000
  );
}

.footerLineContainer {
  margin: 1rem 0;
  height: fit-content;
  display: flex;
  flex-direction: column;
}

.footerLineContainer div {
  margin-right: 1rem;
}

.footerNavTitle {
  font-size: 1.6em;
  color: var(--black);
  font-weight: 700;
}

.footerNavLinks {
  display: flex;
  flex-direction: column;
}

.tagline {
  margin: 0.4rem 0;
}

.footerNavContainer {
  margin: 0.4rem 0;
}
